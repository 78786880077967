<template>
    <div class="MonitorDetailBaseBox">
        <loading v-if="isLoading" size="30px" style="height: 100%;"></loading>
        <div class="container" v-if="!isLoading">
            <scroll v-if="!isLoading&&detailList.length>0" class="wrapper" ref="scroll" :startY="parseInt(startY)">
                <ul ref="wrapper" v-for="(item,index) in detailList" :key="index" class="logList">
                    <li>
                        <div>主题： {{item.subject}}</div>
                        <div>发件人： {{item.fromAddress}}</div>
                        <div class="timeTable">
                            <div>
                                <div>发信时间</div>
                                <div>{{timestampToTime(item.longSentDate)}}</div>
                            </div>
                            <div>
                                <div>开始接收时间</div>
                                <div>{{item.createTime.slice(10,16)}}</div>
                            </div>
                            <div>
                                <div>接收完成时间</div>
                                <div>{{item.finishTime.slice(10,16)}}</div>
                            </div>
                            <div>
                                <div>保存时间</div>
                                <div>{{item.storeTime.slice(10,16)}}</div>
                            </div>
                        </div>
                    </li>
                </ul>
            </scroll>
            <nodata title="暂无数据" img="noCustomer" v-if="detailList.length==0 && !isLoading" style="height: 100%;"></nodata>
        </div>
    </div>
</template>

<script>
import BScroll from 'better-scroll'
import Scroll from '@/components/Scroll/index'
import titleMixin from '@/mixin/title'
export default {
    name: 'MonitorDetail',
    title: '最近接收',
    mixins: [titleMixin],
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            show: false,
            routerParams: {},
            isLoading: false,
            detailList: [],
            scroll: null,
            startY: 0,
            personnelList: []
        }
    },
    async created() {
        this.getDetailList()
    },
    mounted() {
        this.setMenu()
    },
    methods: {
        timestampToTime(timestamp) {
            var date = new Date(parseInt(timestamp)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            // let Y = date.getFullYear() + '-'
            // let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            // let D = date.getDate() + ' '
            let h = date.getHours() + ':'
            let m = date.getMinutes()
            if (m < 10) {
                m = '0' + m + ''
            }
            return h + m
        },
        setScroll() {
            this.$nextTick(() => {
                if (!this.scroll) {
                    this.scroll = new BScroll(this.$refs.wrapper, {
                        click: true,
                        probeType: 3
                    })
                } else {
                    this.scroll.refresh()
                }
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        async getDetailList(type) {
            this.routerParams = this.$route.query
            let listArr = []
            if (JSON.stringify(this.routerParams) != '{}') {
                try {
                    let url = this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_recentMails + this.routerParams.id
                    let params = {
                    }
                    let res = await this.axios.get(url, { params })
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        this.detailList = res.data.data
                        // this.page.total = res.data.data.totalNum
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            return listArr
        }
    },
    watch: {
        async $route() {
            if (this.$route.path == '/mailmonitor/tasklog') {
                this.reGetList()
            }
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
